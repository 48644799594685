<template>
  <div class="page inPage PopupPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Dialog</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="提示弹窗" is-link @click="handShow1"></lee-cell>
        <lee-cell title="提示弹窗（无标题）" is-link @click="handShow2"></lee-cell>
        <lee-cell title="确认弹窗" is-link @click="handShow3"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="使用 Dialog 组件" inset>
        <lee-dialog v-model="show" title="组件标题" message="人生就像玻璃窗上的苍蝇，前途一片光明，却找不到出路。" show-cancel-button @onOpen="onOpen" @onClose="onClose" @onCancel="onCancel" @onConfirm="onConfirm"></lee-dialog>
        <lee-cell title="使用 Dialog 组件" is-link @click="handShow4"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import {showDialog} from '@/leeplus';


const router = useRouter()
const back = () => {
    router.push('/')
}
const show=ref(false)
const handShow1 = () => {
    showDialog({
        title: '人生有两件事最重要',
        message: '<p>一是要学会选择，二是要学会放弃。</p><p>我们的命运，取决于自己的选择。所有的选择都有风险，所有的选择都要付出代价，关键在于你想要什么，愿意放弃什么。</p><p>放弃不是认输，也不是投降，而是一种更重要的选择。</p><p>不懂得放弃，就做不出选择。</p><p>选择与放弃，就是决定命运走向的十字路口。</p>',
        onOpen: () => {
            console.log("打开")
        },
        beforeClose: () => {

        },
        onClose: () => {
            console.log("关闭")
        },
        onConfirm: () => {
            console.log("确定")
        },
        onCancel: () => {
            console.log("取消")
        }
    })
}
const handShow2 = () => {
    showDialog({
        message: '在人类行为学课上我们曾学过，朋友失败时，你难过.朋友成功时，你更难过',
        onOpen: () => {
            console.log("打开")
        },
        beforeClose: () => {

        },
        onClose: () => {
            console.log("关闭")
        },
        onConfirm: () => {
            console.log("确定")
        },
        onCancel: () => {
            console.log("取消")
        }
    })
}
const handShow3 = () => {
    showDialog({
        title: '焦虑文化和自恋文化',
        message: '时钟和镜子改变了人类社会，时钟创造了一种焦虑文化，镜子创造了一种自恋文化。',
        showCancelButton:true,
        onOpen: () => {
            console.log("打开3")
        },
        beforeClose: () => {

        },
        onClose: () => {
            console.log("关闭3")
        },
        onConfirm: () => {
            console.log("确定3")
        },
        onCancel: () => {
            console.log("取消3")
        }
    })
}
const handShow4 = () => {
    show.value=true
}
const onOpen = () => {
    console.log("打开")
}
const onClose = () => {
    console.log("关闭")
}
const onCancel = () => {
    console.log("取消")
}
const onConfirm = () => {
    console.log("确定")
}

</script>
<style>


</style>
